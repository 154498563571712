import Bugsnag, { type Client, type Config, type Plugin } from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { BugsnagPluginCustomError } from '@sonicgarden/bugsnag-plugin-custom-error'

// NOTE: safariではjsが別ドメイン配信の場合にwindow.onerrorでキャッチするエラーが通知されないという問題の暫定対応
const BugsnagPluginAppleCrossOriginError: Plugin = {
  name: 'bugsnag-plugin-apple-crossorigin',

  load(client: Client): void {
    if (!window.navigator.vendor.toLowerCase().includes('apple')) {
      return
    }

    const prevOnError = window.onerror
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    window.onerror = (messageOrEvent, url, lineNo, charNo, error) => {
      // SEE: https://github.com/bugsnag/bugsnag-js/blob/050d51dd699f64a27291d66d9beac0f74b4bf017/packages/plugin-window-onerror/onerror.js#L11
      if (lineNo === 0 && typeof messageOrEvent === 'string' && /Script error\.?/.test(messageOrEvent)) {
        const handledState = { severity: 'error', unhandled: true, severityReason: { type: 'unhandledException' } }
        const event = client.Event.create(new Error(messageOrEvent), true, handledState, 'apple crossorigin onerror', 1)
        client._notify(event)
      }

      prevOnError?.apply(window, [messageOrEvent, url, lineNo, charNo, error])
    }
  },
}

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const loggerOptions = (): Pick<Config, 'logger'> => {
  // eslint-disable-next-line unicorn/no-null
  return import.meta.env.RAILS_ENV === 'test' ? { logger: null } : {}
}

const userOptions = (): Pick<Config, 'user'> => {
  if (!window.N2JK.rails.user) return {}

  const { id, email } = window.N2JK.rails.user
  return {
    user: { id: id.toString(), email },
  }
}

const customErrorPlugin = new BugsnagPluginCustomError({
  ActionCableLinkError: ["Cannot read properties of undefined (reading 'data')"],
  NetworkError: [
    'Network Error',
    'timeout of 0ms exceeded',
    'Request aborted',
    'ネットワーク接続が切れました。',
    'NetworkError when attempting to fetch resource',
    'キャンセルしました',
    'インターネット接続がオフラインのようです。',
    'SSLエラーが起きたため、サーバへのセキュリティ保護された接続を確立できません。',
    '応答を解析できません',
    'The network connection was lost.',
    'Failed to fetch',
    'Unable to preload',
  ],
  MemoryError: [
    'メモリが不足しています。',
    'この操作を完了するための十分な記憶域がありません。',
    'メモリ リソースが不足しているため、この操作を完了できません。',
  ],
  IgnoreSyntaxError: ['Unexpected end of input', '終了していない文字列型の定数です。'],
  ResizeObserverError: ['ResizeObserver loop limit exceeded'],
  LikelyBotError: [() => ['/users/sign_in', '/users/password/new'].includes(window.location.pathname)],
  // eslint-disable-next-line github/unescaped-html-literal
  SafariWebExtensionError: ['<page-ready>', '<get-frame-manager-configuration>'],
})

// NOTE: jsのシンタックスエラーも通知させる為にエントリーポイントを別にしているが他エントリーポイントからも必要となるため
window.bugsnagClient = Bugsnag.start({
  apiKey: '94ef66e59026378a7de45155a1376e65',
  releaseStage: import.meta.env.RAILS_ENV,
  enabledReleaseStages: ['staging', 'production'],
  // NOTE: タスク数等が多くてユーザ操作時の体感速度が遅くなる場合はプロジェクト指定で無効化すると良い
  // onBreadcrumb: () => {
  //   if (import.meta.env.RAILS_ENV === 'production' && location.pathname.startsWith('/projects/27')) {
  //     return false
  //   }
  // },
  plugins: [new BugsnagPluginVue(), customErrorPlugin, BugsnagPluginAppleCrossOriginError],
  ...loggerOptions(),
  ...userOptions(),
})
